export const transition = { duration: 0.5, ease: [0.6, 0.0, 0.4, 1.0] }

export const container = {
  out: {
    transition: { when: "afterChildren", delayChildren: 0.5 },
  },
}

export const item = {
  in: { opacity: 0 },
  out: { opacity: 1, transition: { duration: 2 } },
}

export const fade = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.25, ...transition } },
  exit: { opacity: 0 },
}

export const pulse = {
  initial: { opacity: 1 },
  animate: {
    opacity: 0,
    transition: {
      repeat: Infinity,
      repeatType: "reverse",
      duration: 0.85,
    },
  },
}
