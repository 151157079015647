import React, { useState } from "react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import useSite from "../hooks/use-site"

const Navigation = () => {
  const { title } = useSite()
  const { pathname } = useLocation()
  const [hovered, setHovered] = useState(false)

  const customAria = pathname === "/info" ? "home page" : "info"

  return (
    <nav className="absolute inset-x-0 z-40 flex items-center justify-center h-24 px-12 text-center">
      <Link
        to={pathname === "/info" ? "/" : "/info"}
        aria-label={`Navigate to ${customAria}`}
        className="w-40 font-sans text-2xl uppercase"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {hovered ? (
          <span>{pathname === "/info" ? title : "Info"}</span>
        ) : (
          <span>{title}</span>
        )}
      </Link>
    </nav>
  )
}

export default Navigation
