import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { fade } from "../components/motion"
import Navigation from "../components/navigation"

const Layout = ({ children }) => (
  <>
    <Navigation />
    <AnimatePresence exitBeforeEnter>
      <motion.main
        {...fade}
        className="font-sans text-base antialiased font-normal"
      >
        {children}
      </motion.main>
    </AnimatePresence>
  </>
)

export default Layout
